import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const CookieSettings = ({ isOpen, onClose, onSave }) => {
  const [necessary, setNecessary] = useState(true);
  const [analytics, setAnalytics] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const handleSave = () => {
    onSave({ necessary, analytics, marketing });
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed z-50 inset-0 overflow-y-auto"
        >
          <div className="flex items-center justify-center min-h-screen">
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full"
            >
              <h2 className="text-2xl font-bold mb-4">Cookie-Einstellungen</h2>
              <div className="space-y-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={necessary}
                    onChange={() => setNecessary(!necessary)}
                    className="mr-2"
                    disabled
                  />
                  <span>Notwendige Cookies</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={analytics}
                    onChange={() => setAnalytics(!analytics)}
                    className="mr-2"
                  />
                  <span>Analyse-Cookies</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={marketing}
                    onChange={() => setMarketing(!marketing)}
                    className="mr-2"
                  />
                  <span>Marketing-Cookies</span>
                </label>
              </div>
              <div className="flex justify-end mt-6">
                <button
                  onClick={onClose}
                  className="px-4 py-2 rounded bg-gray-300 text-gray-700 mr-4"
                >
                  Abbrechen
                </button>
                <button
                  onClick={handleSave}
                  className="px-4 py-2 rounded bg-[#006968] text-white"
                >
                  Speichern
                </button>
              </div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  useEffect(() => {
    const settings = localStorage.getItem('cookieSettings');
    if (!settings) {
      setIsVisible(true);
    } else {
      const { necessary, analytics, marketing } = JSON.parse(settings);
      // Apply the settings
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieSettings', JSON.stringify({
      necessary: true,
      analytics: true,
      marketing: true,
    }));
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieSettings', JSON.stringify({
      necessary: true,
      analytics: false, 
      marketing: false,
    }));
    setIsVisible(false);
  };

  const handleSaveSettings = (settings) => {
    localStorage.setItem('cookieSettings', JSON.stringify(settings));
    setIsVisible(false);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          className="fixed bottom-0 left-0 right-0 bg-gray-900 text-white z-50 shadow-lg"
        >
          <div className="container mx-auto px-4 py-4">
            <div className="flex flex-col md:flex-row items-center justify-between gap-4">
              <div className="text-sm text-gray-300">
                <p>
                  Wir verwenden Cookies, um Ihnen die bestmögliche Erfahrung auf unserer Website zu bieten. 
                  Durch die weitere Nutzung der Website stimmen Sie der Verwendung von Cookies zu. 
                  Weitere Informationen finden Sie in unserer{' '}
                  <Link to="/datenschutz" className="text-[#006968] hover:text-[#006968]/80 underline">
                    Datenschutzerklärung
                  </Link>
                  .
                </p>
              </div>
              <div className="flex gap-4">
                <button
                  onClick={handleReject}
                  className="px-6 py-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors"
                >
                  Ablehnen
                </button>
                <button
                  onClick={() => setIsSettingsOpen(true)}
                  className="px-6 py-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors"
                >
                  Einstellungen
                </button>
                <button
                  onClick={handleAccept}
                  className="px-6 py-2 bg-[#006968] text-white rounded hover:bg-[#006968]/90 transition-colors"
                >
                  Akzeptieren
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
      <CookieSettings
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        onSave={handleSaveSettings}
      />
    </AnimatePresence>
  );
};

export default CookieBanner; 