import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MapPinIcon, PhoneIcon, DevicePhoneMobileIcon, EnvelopeIcon } from '@heroicons/react/24/outline';

const ExternalLinkPopup = ({ url, onClose }) => {
  const handleProceed = () => {
    window.open(url, '_blank');
    onClose();
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg className="h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  Sie verlassen sv-geb.de
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Sie werden auf eine externe Website weitergeleitet. Möchten Sie fortfahren?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#006968] text-base font-medium text-white hover:bg-[#006968]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#006968] sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleProceed}
            >
              Fortfahren
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Logo und Beschreibung */}
          <div className="space-y-4">
            <img 
              src="/images/logo/sv-geb-logo-white.png" 
              alt="SV-GEB Logo" 
              className="h-12 w-auto"
            />
            <div onClick={openPopup} className="cursor-pointer">
              <img 
                src="/images/logos/dekra-shield.png" 
                alt="DEKRA Zertifizierung" 
                className="h-16 w-auto mt-4 brightness-0 invert"
              />  
            </div>
          </div>

          {/* Kontakt */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Kontakt</h3>
            <div className="space-y-3 text-gray-400">
              <div className="flex items-start space-x-3">
                <MapPinIcon className="w-5 h-5 mt-1 flex-shrink-0" />
                <div>
                  <p>Lange Reihe 17</p>
                  <p>21635 Jork</p>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <PhoneIcon className="w-5 h-5 flex-shrink-0" />
                <p>Telefon: 04162 / 24 54 42</p>
              </div>
              <div className="flex items-center space-x-3">
                <DevicePhoneMobileIcon className="w-5 h-5 flex-shrink-0" />
                <p>Mobil: 0172 / 83 66 985</p>
              </div>
              <div className="flex items-center space-x-3">
                <EnvelopeIcon className="w-5 h-5 flex-shrink-0" />
                <p>E-Mail: info@sv-geb.de</p>
              </div>
            </div>
          </div>

          {/* Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-400 hover:text-white transition-colors">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/uber-uns" className="text-gray-400 hover:text-white transition-colors">
                  Über uns
                </Link>
              </li>
              <li>
                <Link to="/leistungen" className="text-gray-400 hover:text-white transition-colors">
                  Leistungen
                </Link>
              </li>
              <li>
                <Link to="/kontakt" className="text-gray-400 hover:text-white transition-colors">
                  Kontakt
                </Link>
              </li>
            </ul>
          </div>

          {/* Rechtliches */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Rechtliches</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/impressum" className="text-gray-400 hover:text-white transition-colors">
                  Impressum
                </Link>
              </li>
              <li>
                <Link to="/datenschutz" className="text-gray-400 hover:text-white transition-colors">
                  Datenschutz
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400 text-sm">
          <p>&copy; {new Date().getFullYear()} SV-GEB. Alle Rechte vorbehalten.</p>
        </div>
      </div>

      {isPopupOpen && (
        <ExternalLinkPopup 
          url="https://www.dekra.de/de/startseite/"
          onClose={closePopup}
        />
      )}
    </footer>
  );
};

export default Footer; 