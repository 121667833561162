import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: 'Welche Unterlagen benötigt der Sachverständige für die Begutachtung?',
      answer:
        'Für eine effiziente Begutachtung benötigt der Sachverständige in der Regel die Schadenmeldung, Fotos des Schadens, Polizeiberichte (falls vorhanden) und eine Auflistung der beschädigten Gegenstände. Je nach Schadensfall können weitere Unterlagen erforderlich sein.',
    },
    {
      question: 'Wie lange dauert es in der Regel, bis ein Gutachten erstellt ist?',
      answer:
        'Die Dauer hängt von der Komplexität des Schadensfalls ab. In der Regel ist mit einer Bearbeitungszeit von 2-3 Wochen nach der Besichtigung durch den Sachverständigen zu rechnen.',
    },
    {
      question: 'Wie läuft die Beauftragung eines Sachverständigen ab?',
      answer: 'Nach Ihrer Kontaktaufnahme vereinbaren wir zeitnah einen Termin zur Schadensaufnahme vor Ort. Dort erfassen wir alle schadenrelevanten Daten, dokumentieren den Zustand mit Fotos und besprechen das weitere Vorgehen mit Ihnen. Im Anschluss erstellen wir ein unabhängiges Gutachten.',
    },
    {
      question: 'Was sind die nächsten Schritte nach der Begutachtung durch den Sachverständigen?',
      answer: 'Nach der Begutachtung erstellt der Sachverständige ein detailliertes Gutachten, in dem er den Schaden beschreibt, bewertet und eine Empfehlung zur Schadensregulierung ausspricht. Dieses Gutachten wird in erster Linie der Versicherung zur Verfügung gestellt. Es dient als Grundlage für die Entscheidung über die Schadensregulierung und eventuelle Verhandlungen zwischen der Versicherung und dem Versicherungsnehmer.',
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="bg-gray-100 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl font-extrabold text-anthracite mb-8">Häufig gestellte Fragen</h2>
          <div className="bg-white shadow-md rounded-lg divide-y divide-gray-200">
            {faqData.map((item, index) => (
              <div key={index}>
                <button
                  className="flex justify-between items-center w-full px-6 py-4 text-left text-anthracite font-semibold focus:outline-none hover:bg-gray-50 transition duration-300"
                  onClick={() => toggleAccordion(index)}
                >
                  <span>{item.question}</span>
                  <motion.span
                    initial={{ rotate: 0 }}
                    animate={{ rotate: activeIndex === index ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                    className="text-primary"
                  >
                    ▼
                  </motion.span>
                </button>
                <AnimatePresence>
                  {activeIndex === index && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="overflow-hidden"
                    >
                      <div className="px-6 pb-4">
                        <p className="text-gray-600">{item.answer}</p>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQAccordion; 