import React from 'react';
import { motion } from 'framer-motion';
import ContactSection from '../sections/ContactSection';
import FAQAccordion from '../sections/FAQAccordion';
import CookieBanner from '../common/CookieBanner';

const ComingSoonPage = () => {
  return (
    <div>
      <CookieBanner />
      <section className="relative min-h-[90vh] overflow-hidden">
        {/* Hintergrundbild */}
        <div className="absolute inset-0 z-0">
          <motion.img
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1.5 }}
            src="/images/hero/beratungsgespraech.png"
            alt="Beratungsgespräch"
            className="w-full h-full object-cover"
          />
          {/* Vertikaler Overlay auf der linken Seite */}
          <div className="absolute inset-0 bg-gradient-to-r from-white via-white/95 to-transparent w-[60%]" />
        </div>

        {/* Content */}
        <div className="relative z-10 container mx-auto px-4 pt-32 pb-24 min-h-[90vh] flex items-center">
          <div className="relative max-w-3xl">
            <div className="relative z-10">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.6 }}
                className="text-5xl md:text-6xl font-bold text-primary mb-6"
              >
                Willkommen bei SV-GEB
              </motion.h1>

              <motion.h2
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.6 }}
                className="text-3xl md:text-4xl font-semibold text-anthracite mb-12"
              >
                Wir verbessern unseren digitalen Auftritt für Sie!
              </motion.h2>

              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.6 }}
                className="text-xl md:text-2xl mb-12 text-anthracite leading-relaxed"
              >
                Unser Team arbeitet mit Hochdruck daran, unsere Website und Dienstleistungen zu verbessern, um Ihr Erlebnis zu optimieren. Vielen Dank für Ihre Geduld und Ihr Verständnis!
              </motion.p>

              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.8 }}
                className="text-xl md:text-2xl mb-16 text-anthracite leading-relaxed"
              >
                Wir sind ein regional sowie überregional tätiges Sachverständigenbüro, spezialisiert auf Sach- und Haftpflichtschäden im Versicherungs- und Bausektor.
              </motion.p>

              <motion.ul
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.8 }}
                className="text-xl md:text-2xl text-anthracite leading-relaxed custom-arrow-list space-y-6 md:space-y-8"
              >
                <motion.li
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 1.0 }}
                  className="relative pl-8 md:pl-10"
                >
                  Sach- und Haftpflichtschäden
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 1.2 }}
                  className="relative pl-8 md:pl-10"
                >
                  Baubegleitung  
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 1.4 }}
                  className="relative pl-8 md:pl-10"
                >
                  Gebäudeenergieberatung
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 1.6 }}
                  className="relative pl-8 md:pl-10"
                >
                  Nachlassverwaltung
                </motion.li>
              </motion.ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <ContactSection />
        </div>
      </section>
      <section className="bg-gray-100 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <FAQAccordion />
        </div>
      </section>
      <style jsx="true">{`
        .custom-arrow-list li::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1.5rem;
          height: 1.5rem;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23006968'%3E%3Cpath d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: contain;
        }

        @media (max-width: 768px) {
          .custom-arrow-list li::before {
            width: 1.2rem;
            height: 1.2rem;
          }
        }
      `}</style>
    </div>
  );
};

export default ComingSoonPage; 