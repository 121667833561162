import React from 'react';

const DatenschutzSection = () => {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto max-w-3xl px-4">
        <h1 className="mb-8 text-3xl font-bold text-anthracite">Datenschutzerklärung</h1>
        
        <div className="prose max-w-none">
          <h2>Geltungsbereich</h2>
          <p>
            Diese Datenschutzerklärung informiert die Nutzer gemäß der Europäischen Datenschutzgrundverordnung
            (EU-DSGVO), des Bundesdatenschutzgesetzes (BDSG) und des Telemediengesetzes (TMG) über die Art,
            den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch die
          </p>

          <h3>Websitebetreiberin:</h3>
          <p>
            Sachverständige & Gebäudeenergieberater (HwK)<br />
            Dipl.-Psych. Angela Deland-Glöde<br />
            Lange Reihe 17<br />
            21635 Jork, Deutschland
          </p>
          
          <p>
            Mobil.: 0172 / 83 66 985<br />
            Festnetz: 04162 / 24 54 42<br />
            Email: Info@sv-geb.de<br />
            Webseite: www.sv-geb.de
          </p>

          <p>
            Die Betreiberin dieser Seiten nimmt den Schutz Ihrer persönlichen Daten sehr ernst und behandelt
            Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Vorschriften. Bitte
            beachten Sie, dass die Datenübertragung im Internet grundsätzlich mit Sicherheitsrisiken verbunden
            sein kann. Ein vollumfänglicher Schutz vor dem Zugriff durch Fremde ist nicht realisierbar.
          </p>

          <h2>Zugriffsdaten</h2>
          <p>
            Die Websitebetreiberin bzw. der Seitenprovider erhebt Daten über Zugriffe auf die Seite und
            speichert diese als „Server-Logfiles" ab. Protokolliert werden:
          </p>
          <ul>
            <li>Besuchte Website</li>
            <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
            <li>Menge der gesendeten Daten in Byte</li>
            <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Verwendete IP-Adresse (in anonymisierter Form)</li>
          </ul>

          <p>
            Die Daten werden lediglich zur statistischen Auswertung und zur Verbesserung der Website verwendet.
            Die Websitebetreiberin behält sich allerdings vor, die Server-Logfiles nachträglich zu überprüfen,
            sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.
          </p>

          <h2>Cookies</h2>
          <p>
            Diese Website verwendet Cookies. Dabei handelt es sich um kleine Textdateien, die auf Ihrem Endgerät
            gespeichert werden. Ihr Browser greift auf diese Dateien zu. Durch den Einsatz von Cookies erhöht
            sich die Benutzerfreundlichkeit und Sicherheit dieser Website. Gängige Browser bieten die
            Einstellungsoption, Cookies nicht zuzulassen. Hinweis: Es ist möglich, dass Sie nicht alle
            Funktionen dieser Website ohne Einschränkungen nutzen können, wenn Sie entsprechende Einstellungen
            vornehmen.
          </p>

          <h2>Umgang mit personenbezogenen Daten</h2>
          <p>
            Die Websitebetreiberin erhebt, nutzt und gibt Ihre personenbezogenen Daten nur dann weiter, wenn
            dies im gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung einwilligen.
            Personenbezogene Daten sind Informationen, die genutzt werden, um Ihre Person zu identifizieren und
            die zu Ihnen zurückverfolgt werden können, wie z.B. Ihr Name, Ihre E-Mail-Adresse und
            Telefonnummer.
          </p>

          <h2>Umgang mit Kontaktdaten</h2>
          <p>
            Nehmen Sie mit der Websitebetreiberin durch die angebotenen Kontaktmöglichkeiten Verbindung auf,
            werden Ihre Angaben gespeichert, damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage
            zurückgegriffen werden kann. Ohne Ihre Einwilligung werden diese Daten nicht an Dritte
            weitergegeben.
          </p>

          <h2>Analyse-Tools und Werbung</h2>
          <p>
            Diese Website verwendet möglicherweise Google Analytics, um die Website-Nutzung zu analysieren. Die
            daraus gewonnenen Daten werden genutzt, um unser Angebot noch weiter zu verbessern. Nähere
            Informationen zu Google Analytics finden Sie weiter unten unter der Überschrift „Google Analytics".
          </p>

          <h2>Google Analytics</h2>
          <p>
            Diese Website nutzt den Dienst „Google Analytics", der von der Google Inc. (1600 Amphitheatre
            Parkway Mountain View, CA 94043, USA) angeboten wird. Google Analytics verwendet „Cookies". Die
            durch die Cookies generierten Informationen über Ihre Benutzung dieser Website werden in der Regel
            an einen Server von Google in den USA übertragen und dort gespeichert. Auf dieser Website ist die
            IP-Anonymisierung aktiviert. Die IP-Adresse der Nutzer wird gekürzt. Nur in Ausnahmefällen wird
            die volle IP-Adresse an einen Server von Google übertragen und dort gekürzt. Im Auftrag der
            Betreiberin dieser Website wird Google diese Informationen verwenden, um Ihre Nutzung der Website
            auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber der Websitebetreiberin
            zu erbringen.
          </p>

          <p>
            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
            verhindern; wir weisen jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die
            Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl.
            Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie
            das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: Browser Add
            On zur Deaktivierung von Google Analytics.
          </p>

          <h2>Rechte des Nutzers: Auskunft, Berichtigung und Löschung</h2>
          <p>
            Als Nutzer erhalten Sie auf Anfrage kostenlose Auskunft darüber, welche personenbezogenen Daten
            über Sie gespeichert wurden. Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur
            Aufbewahrung von Daten (z.B. Vorratsdatenspeicherung) kollidiert, haben Sie ein Recht auf
            Berichtigung falscher Daten und auf die Sperrung oder Löschung Ihrer personenbezogenen Daten.
          </p>
        </div>
      </div>
    </section>
  );
};

export default DatenschutzSection; 