import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import HeroSection from './components/sections/HeroSection';
import AboutSection from './components/sections/AboutSection';
import ServicesSection from './components/sections/ServicesSection';
import ContactStrip from './components/sections/ContactStrip';
import ComingSoonPage from './components/pages/ComingSoonPage';
// import AboutPage from './components/pages/AboutPage';
// import ServicesPage from './components/pages/ServicesPage';
// import ContactPage from './components/pages/ContactPage';
import DatenschutzPage from './components/pages/DatenschutzPage';
import ImpressumSection from './components/sections/ImpressumSection';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <Routes>
        <Route path="/" element={<ComingSoonPage />} />
        {/* <Route path="/uber-uns" element={<AboutPage />} />
        <Route path="/leistungen" element={<ServicesPage />} />
        <Route path="/kontakt" element={<ContactPage />} /> */}
        <Route path="/datenschutz" element={<DatenschutzPage />} />
        <Route path="/impressum" element={<ImpressumSection />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;