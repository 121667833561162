import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuVariants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: -20 },
  };

  return (
    <nav className="bg-white shadow-md sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img src="/images/logo/sv-geb-logo.png" alt="SV-GEB Logo" className="h-12 w-auto" />
            </Link>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            {/* <Link to="/uber-uns" className="text-anthracite hover:text-primary px-3 py-2">
              Über uns
            </Link>
            <Link to="/leistungen" className="text-anthracite hover:text-primary px-3 py-2">
              Leistungen
            </Link>
            <Link to="/kontakt" className="text-anthracite hover:text-primary px-3 py-2">
              Kontakt
            </Link> */}
            <Link to="/datenschutz" className="text-anthracite hover:text-primary px-3 py-2">
              Datenschutz
            </Link>
            <Link to="/impressum" className="text-anthracite hover:text-primary px-3 py-2">
              Impressum
            </Link>
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-anthracite hover:text-primary focus:outline-none"
            >
              <span className="sr-only">Menü öffnen</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
            transition={{ duration: 0.2 }}
            className="md:hidden bg-white shadow-lg absolute top-16 inset-x-0 z-50"
          >
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {/* <Link
                to="/uber-uns"
                className="text-anthracite hover:text-primary block px-3 py-2 rounded-md text-base font-medium"
              >
                Über uns
              </Link>
              <Link
                to="/leistungen"
                className="text-anthracite hover:text-primary block px-3 py-2 rounded-md text-base font-medium"
              >
                Leistungen
              </Link>
              <Link
                to="/kontakt"
                className="text-anthracite hover:text-primary block px-3 py-2 rounded-md text-base font-medium"
              >
                Kontakt
              </Link> */}
              <Link
                to="/datenschutz"
                className="text-anthracite hover:text-primary block px-3 py-2 rounded-md text-base font-medium"
              >
                Datenschutz
              </Link>
              <Link
                to="/impressum"
                className="text-anthracite hover:text-primary block px-3 py-2 rounded-md text-base font-medium"
              >
                Impressum
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar; 